import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from './sharedComponents';
import Sidebar from './sidebar';

import linkedIn from '../assets/logo/linkedIn.png';

const FooterCard = styled.footer`
	padding: 0.5rem;
	box-shadow: 0 10px 11px -11px #00000059;
	//position: fixed;
	width: 100%;
	background: #c77dff3e;
	z-index: 9;
`;

const NavTabsWrap = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 2fr 2fr 1fr;
	align-items: center;

	@media (max-width: 550px) {
		grid-template-columns: 11rem 1fr min-content;
	}
`;

const Logo = styled.img`
	user-select: none;
	text-decoration-line: none;
	margin: 0;
	object-position: center;
	height: 2rem;
	object-fit: contain;
	margin-bottom: -8px;
`;

const NavTab = styled.a`
	min-width: 6rem;
	text-align: center;
	user-select: none;
	border-radius: 0.2rem;
	padding: 0.3rem;
	background: ${({ theme, primary }) => primary && theme.accentPrimary};
	color: ${({ theme, primary }) => primary && '#FFF'};
	text-decoration: none;
	transition: all 0.3s ease-in-out;

	cursor: pointer;
	position: relative;

	&:after {
		content: ${({ primary }) => !primary && ''};
		position: absolute;
		left: 0;
		bottom: 0;
		height: 2px;
		background: ${(props) => props.theme.accentPrimary};
		width: 0;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		color: ${({ theme, primary }) =>
			primary ? '#FFF' : theme.accentPrimary};
		background: ${({ theme, primary }) => primary && theme.accentLight};
		&:after {
			width: 100%;
		}
	}

	// @media (max-width: 550px) {
	// 	display: none;
	// }
`;

const Footer = ({ siteTitle }) => (
	<FooterCard>
		<div className="container">
			<NavTabsWrap>
				{/* <Link
					to="/"
					style={{
						textDecorationLine: 'none',
						textDecoration: 'none'
					}}
				>
					<Logo src={logo} alt="" />
				</Link> */}

				<div className="container">
					© {new Date().getFullYear()}, <br />Stephanie English Career
					Management
				</div>
				<div />
				{/* <NavTab to="/faq" activeClassName="active">
          FAQs
        </NavTab>
        <NavTab to="/about" activeClassName="active">
          About
        </NavTab> */}
				<NavTab
					href="https://www.linkedin.com/in/senglish/"
					target="_blank"
					rel="noopener norefferer"
				>
					<Logo src={linkedIn} />
				</NavTab>

				{/* <Sidebar /> */}
			</NavTabsWrap>
		</div>
	</FooterCard>
);

Footer.propTypes = {
	siteTitle: PropTypes.string
};

Footer.defaultProps = {
	siteTitle: ``
};

export default Footer;

import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Button } from './sharedComponents';

import { showRegistrationForm } from '../components/helpers';

import logo from '../assets/logo/logo-option-2.png';
import logoMobile from '../assets/icons/favi.png';

const HeaderCard = styled.header`
	padding: 0.5rem;
	box-shadow: 0 10px 11px -11px #00000059;
	position: fixed;
	width: 100%;
	background: white;
	z-index: 9;
`;

const NavTabsWrap = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 11rem 1fr min-content min-content min-content;
	align-items: center;

	@media (max-width: 550px) {
		grid-template-columns: 9rem 1fr min-content;
	}
`;

const Logo = styled.img`
	user-select: none;
	text-decoration-line: none;
	margin: 0;
	object-position: center;
	height: 3rem;
	object-fit: contain;
	margin-bottom: -8px;

	@media (max-width: 550px) {
		height: 2rem;
	}
`;

const NavTab = styled(Link)`
  min-width: 6rem;
  text-align: center;
  user-select: none;
  border-radius: 0.2rem;
  padding: 0.3rem;
  background: ${({ theme, primary }) => primary && theme.accentPrimary};
  color: ${({ theme, primary }) => primary && '#FFF'};
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  cursor: pointer;
  position: relative;

  &:after {
    content: ${({ primary }) => !primary && ''};
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${(props) => props.theme.accentPrimary};
    width: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${({ theme, primary }) => (primary ? '#FFF' : theme.accentPrimary)};
    background: ${({ theme, primary }) => primary && theme.accentLight};
    &:after {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

const Header = ({ siteTitle }) => {
	const [ windowWidth, setWindowWidth ] = useState(1000);
	const imageUrl = windowWidth >= 550 ? logo : logoMobile;

	// const handleWindowResize = () => {
	// 	setWindowWidth(window.innerWidth);
	// };

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth);
		});

		return () => {
			window.removeEventListener('resize', () => {
				setWindowWidth(window.innerWidth);
			});
		};
	});

	return (
		<HeaderCard>
			<div className="container">
				<NavTabsWrap>
					<Link
						to="/"
						style={{
							textDecorationLine: 'none',
							textDecoration: 'none',
							width: '100%'
						}}
					>
						<Logo src={imageUrl} alt="" />
					</Link>

					<div />
					{/* <NavTab to="/faq" activeClassName="active">
          FAQs
        </NavTab>
        <NavTab to="/about" activeClassName="active">
          About
        </NavTab> */}

					<Button primary onClick={showRegistrationForm}>
						Get In Touch!
					</Button>

					{/* <Sidebar /> */}
				</NavTabsWrap>
			</div>
		</HeaderCard>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
